import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Gallery from "./Gallery";
import { HashRouter, Route, Switch } from "react-router-dom";
import GalleryDetails from "./GalleryDetails";
import { Helmet } from "react-helmet";
import "./styles/style.scss";

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <link rel="icon" href="/favicon.png" type="image/png" />
      {/* TO DO: update these */}
      {/* <meta property="og:url" content="https://example.com/page.html"> */}
      {/* <meta property="og:type" content="website"> */}
      {/* <meta property="og:title" content="Content Title"> */}
      <meta
        property="og:image"
        content={`${window.location.origin}/images/og-image.png`}
      />
      {/* <meta property="og:image:alt" content="A description of what is in the image (not a caption)"> */}
      {/* <meta property="og:description" content="Description Here"> */}
      {/* <meta property="og:site_name" content="Site Name"> */}
      {/* <meta property="og:locale" content="en_US"> */}
      {/* <meta property="article:author" content=""> */}
    </Helmet>
    <HashRouter basename="/">
      <Switch>
        <Route path="/gallery/:id" component={GalleryDetails} />
        <Route path="/gallery" component={Gallery} />
        <Route exact path="/" component={App} />
      </Switch>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
