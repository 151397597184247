import { getContractWithSigner } from "./config";
import { TransactionResponse } from "@ethersproject/abstract-provider";

export const claimName = async (tokenId: string, name: string, isUpdate: boolean) => {
  const { contract } = getContractWithSigner('registrar');

  const method = isUpdate ? 'update' : 'claim';
  const resp = await (contract[method](parseInt(tokenId, 10), name) as Promise<TransactionResponse>);
  await resp.wait(1);

  return resp;
};

export default claimName;
