import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from './GalleryHeader.module.scss';
import Button from '../Button';
import hasToken, { getFirstOwnedToken } from '../../crypto/hasToken';
import appState, { StateShape } from '../../AppState';

interface GalleryHeaderProps {
}
const GalleryHeader: React.FC<GalleryHeaderProps> = () => {
  const [ownedTokenId, setOwnedTokenId] = useState<string>(appState.ownedTokenId);
  const [status, setStatus] = useState<'done' | 'loading' | 'no token' | 'error'>('done');
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  const toggleMobileMenu = useCallback(() => {
    setMobileMenuOpen(!mobileMenuOpen);
  }, [setMobileMenuOpen, mobileMenuOpen]);

  useEffect(() => {
    const cb = ({ ownedTokenId: fetchedId }: StateShape) => {
      if (fetchedId !== ownedTokenId) {
        setOwnedTokenId(fetchedId);
      }
    };

    appState.onChange(cb);

    return () => {
      appState.offChange(cb);
    };
  }, [setOwnedTokenId, ownedTokenId]);

  console.log(appState.address);

  const onConnectWallet = useCallback(async () => {
    if (status === 'loading') {
      return;
    }
    setStatus('loading');

    try {
      if (!appState.address) {
        const { address, hasToken: addressHasToken } = await hasToken();
        appState.address = address;

        if (!address && !addressHasToken) {
          setStatus('no token');
          return;
        }
      }

      const tokenId = await getFirstOwnedToken(appState.address);
      appState.ownedTokenId = tokenId.toString();

      setStatus('done');
    } catch (err) {
      console.error(err);
      setStatus('error');
    }
  }, [setStatus, status]);

  return (<><div className={styles.header}>
    <Link to="/">
      <img src="./images/logo.svg" alt="KONG" />
    </Link>
    <div className={styles.children}>
      {
        ownedTokenId &&
        <Link to={`/gallery/${ownedTokenId}`} className={cx(styles.headerLink, styles.mono)}>view your citizen</Link>
      }
      <Link to="/" className={styles.headerLink}>
        Home
      </Link>
      <Link to="/gallery" className={styles.headerLink}>
        The Agora
      </Link>
      {
        !ownedTokenId &&
          <Button small className={cx(styles.profileButton, styles.connectButton)} onClick={onConnectWallet}>Connect your wallet</Button>
      }
      <img src={mobileMenuOpen ? "/images/closeX.svg" : "/images/hamburger.svg"} className={styles.mobileMenuTrigger} onClick={toggleMobileMenu} />
    </div>
    <div className={cx(styles.mobileMenu, mobileMenuOpen && styles.open)}>
      <Link to="/" className={styles.mobileLink}>
          Home
        </Link>
      <Link to="/gallery" className={styles.mobileLink}>
        The Agora
      </Link>
      {
        ownedTokenId ?
        <Link to={`/gallery/${ownedTokenId}`}><Button small className={cx(styles.profileButton, styles.mobileButton)}>view your citizen</Button></Link> :
        <Button small className={cx(styles.profileButton, styles.mobileButton)} onClick={onConnectWallet}>Connect your wallet</Button>
      }
    </div>
  </div>
  <div className={cx(styles.mobileOverlay, mobileMenuOpen && styles.open)} />
  </>);
};

export default GalleryHeader;
