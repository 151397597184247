import React from "react";
import { Link } from 'react-router-dom';
import { TokenObject } from "../../firebase/tokenCache";

import styles from './TokenTile.module.scss';

interface TokenTileProps {
  token: TokenObject;
}
const TokenTile: React.FC<TokenTileProps> = ({ token }) => {

  return (<Link to={`/gallery/${token.id}`}>
    <div className={styles.token}>
      <img src={token.image} alt="Citizenship Token" className={styles.bust} />
      <div className={styles.tokenInfo}>
        <p className={styles.tokenName}>{token.name || 'anonymous'}</p>
        <div className={styles.tokenPoaps}>
          {
            token.poaps.slice(0, 3).map(poap => (<img src={poap.image_url} alt={poap.name} title={poap.name} key={poap.id} className={styles.tokenPoap} />))
          }
        </div>
      </div>
    </div>
  </Link>)
};

export default TokenTile;