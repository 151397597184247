import { httpsCallable } from "@firebase/functions";
import React, { useCallback, useState } from "react";
import setBio from "../../crypto/setBio";
import { functions } from "../../firebase/firebase";
import Button from "../Button";
import Modal from "../Modal";
import ModalHeader from "../ModalHeader";

import styles from './ClaimModal.module.scss';

interface BioModalProps {
  open: boolean;
  onClose: () => void;
  claimedName: string;
}
const BioModal: React.FC<BioModalProps> = ({ open, onClose, claimedName }) => {
  const [bio, setBioVal] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  
  const handleBioChange = useCallback((ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBioVal(ev.target.value);
  }, [setBioVal]);

  const handleSubmit = useCallback(async () => {
    if (!bio || status === 'loading...') {
      return;
    }
    setStatus('loading...');

    try {
      // await setBio(`${claimedName}.kong.test`, bio);

      const resp = await httpsCallable<{claimedName: string}, { error: { message: string } | null  }>(functions, 'setBio')({ claimedName });

      if (resp.data?.error && resp.data.error.message) {
        setStatus(resp.data.error.message);
        return;
      }
    } catch (err: any) {
      if (typeof err?.message === 'string' && err.message.includes('Caller must own')) {
        setStatus('Failed to set bio, please ensure it is owned by the connected wallet');
      } else {
        console.error(err);
        setStatus('Unexpected error setting bio');
      }
      return;
    }

    setStatus('');
    onClose();
  }, [bio, setStatus, status, onClose, claimedName]);


  return (<Modal open={open} onClose={onClose} closeable className={styles.modal}>
    <ModalHeader title="Update Profile">{status}</ModalHeader>

    <textarea className={styles.bioInput} value={bio} onChange={handleBioChange} placeholder="Type your bio here"></textarea>

    <Button small className={styles.registerButton} onClick={handleSubmit} disabled={status === 'loading...'} >Save</Button>

  </Modal>);
};

export default BioModal;