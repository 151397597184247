import firestore from './firestore';
import { Timestamp, collection, addDoc } from 'firebase/firestore';
import { AddressFormShape } from "../components/AddressForm";

export interface IOrder {
  address: AddressFormShape;
  createdAt: Timestamp;
  tokenId: string;
  ethAddress: string;
  signature: string;
  isUpdate?: boolean;
}

export const ordersCollection = collection(firestore, 'orders');

export interface CreateOrderPayload {
  address: AddressFormShape;
  tokenId: string;
  recipientAddress: string;
  signature: string;
  timestamp: Date;
  isUpdate?: boolean;
}

const createOrder = (payload: CreateOrderPayload) => {
  const order: IOrder = {
    address: payload.address,
    tokenId: payload.tokenId,
    createdAt: Timestamp.fromDate(payload.timestamp),
    ethAddress: payload.recipientAddress,
    signature: payload.signature,
  };

  if (payload.isUpdate) {
    order.isUpdate = payload.isUpdate;
  }

  return addDoc(ordersCollection, order);
};

export default createOrder;