import { getContract, getContractWithSigner } from "./config"


let hasConnected = false;

const hasToken = async () => {
  if (!hasConnected) {
    await window.ethereum.request({ method: 'eth_requestAccounts' })
    hasConnected = true;
  }

  const {contract, signer} = getContractWithSigner('erc721')

  const address = await signer.getAddress();
  const balanceResponse = await contract.balanceOf(address);

  return { address, hasToken: balanceResponse > 0 };
}

export const getFirstOwnedToken = async (address: string) => {
  const { contract } = getContract('erc721');

  const tokenId: number = await contract.tokenOfOwnerByIndex(address, 0);

  return tokenId;
}

export default hasToken;