import { getContractWithSigner } from "./config";
import { TransactionResponse, TransactionReceipt } from "@ethersproject/abstract-provider";

const zeroAddr = '0x0000000000000000000000000000000000000000000000000000000000000000';

export const getTokenFromReceipt = (receipt: TransactionReceipt) => {
  const mintingLog = receipt.logs.find(log => log.topics.length === 4 && log.topics[1] === zeroAddr);

  if (!mintingLog) {
    throw new Error('Failed to find NFT id in transaction log');
  }

  console.log(mintingLog)

  const ethAddress = '0x' + (mintingLog.topics[2]).slice(-40,-1);
  const rawTokenId = mintingLog.topics[3];

  // strip leading zeros, convert to decimal
  const tokenNum = parseInt(rawTokenId, 16);
  return { token: tokenNum.toString(10), ethAddress };
}

const burn = async () => {
  const { contract } = getContractWithSigner('burnmint');

  const result = await (contract.burnTokenToMint() as Promise<TransactionResponse>);
  const receipt = await result.wait(1);

  return getTokenFromReceipt(receipt);
};

export default burn;