import React from "react";
import { AddressFormShape } from "./AddressForm";
import ModalHeader from "./ModalHeader";
import { ReactComponent as ConfirmationCheck } from '../icons/confirmation_check.svg';

import styles from './ConfirmationScreen.module.scss';
import Button from "./Button";

interface ConfirmationScreenProps {
  address?: AddressFormShape;
  nftTokenId?: string;
  onClose: () => void;
}
const ConfirmationScreen: React.FC<ConfirmationScreenProps> = ({ onClose, address, nftTokenId }) => {
  if (!address) {
    return null;
  }

  return (<div>
    <ModalHeader title="Order Complete" tight icon={<ConfirmationCheck className={styles.icon} />}>
    You will need to reauthenticate to the <a href="https://discord.gg/dypeg4JfTX" target="_blank" rel="noopener noreferrer" >KONG Land Discord</a> $CITIZEN rooms using your new ERC-721 token. 
    <br/><br/>
    Your NFT will be revealed using your KONG Land passport.
    </ModalHeader>
    <hr />
    <div className={styles.split}>
      <div>
        <p className={styles.sectionTitle}>Shipping Address</p>
        <p className={styles.mono}>
          {address.fullName}<br />
          {address.line1}<br />
          {address.line2}{address.line2 && <br />}
          {address.city}, {address.state} {address.zip}<br />
          {address.country}
        </p>
      </div>
      {
        nftTokenId && (
          <div className={styles.mono}>
            <p className={styles.sectionTitle}>NFT Token Id</p>
            <p>#{nftTokenId}</p>
          </div>
        )
      }
      
    </div>
    <Button className={styles.closeButton} onClick={onClose}>Close</Button>
  </div>);
};

export default ConfirmationScreen;