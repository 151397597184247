import React, { useState } from "react";

import { Select, TextArea, TextInput } from "./Inputs";

import styles from './AddressForm.module.scss';
import { useCallback } from "react";
import Button from "./Button";

import { getCountries, getStates } from 'country-state-picker';
import ModalHeader from "./ModalHeader";

interface AddressFormProps {
  children?: React.ReactNode;
  nftNumber?: string;
  onSubmit: (form: AddressFormShape) => void;
  submitError?: string;
}

export interface AddressFormShape {
  fullName: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  email: string;
  notes: string;
}

function useFieldOnChange(value: AddressFormShape, onChange: (val: AddressFormShape) => void, field: keyof AddressFormShape, clearErrors: (field: keyof AddressFormErrors) => void) {
  return useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    onChange({
      ...value,
      [field]: ev.target.value,
    });
    clearErrors(field);
  }, [value, onChange, clearErrors, field]);
}

const statePlaceholder = { label: 'State', value: '' };
const countries = (getCountries() as ({ name: string, code: string, dial_code: string })[])
  .filter(country => !['cu', 'gg', 'ir', 'kp', 'ps', 'mm', 'ua', 'af'].includes(country.code));

const usStates = (getStates('us') as string[]).map(state => ({ label: state, value: state }));
usStates.unshift(statePlaceholder);

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// 2nd element is user-facing name for error messages
const requiredFields: ([keyof AddressFormShape, string])[] = [
  ['line1', 'address'], ['city', 'city'], ['state', 'state'], ['zip', 'zip'], ['country', 'country'], ['email', 'email']
];

type AddressFormErrors = { [k in keyof AddressFormShape]?: string }

const AddressForm: React.FC<AddressFormProps> = ({ nftNumber, onSubmit, submitError }) => {
  const [value, onChange] = useState<AddressFormShape>({
    fullName: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    country: 'us',
    email: '',
    notes: '',
  });
  const [statesOptions, setStatesOptions] = useState<({ label: string, value: string })[]>(usStates);
  const [errors, setErrors] = useState<AddressFormErrors>({});

  const clearError = useCallback((field: keyof AddressFormErrors) => {
    setErrors({
      ...errors,
      [field]: '',
    })
  }, [errors, setErrors]);

  const handleSubmit = useCallback(() => {
    const missingFields = requiredFields.filter(field => !value[field[0]]);

    if (!value.fullName) {
      setErrors({
        fullName: 'Please enter your name',
      });
      return;
    }

    if (missingFields.length > 0) {
      const errors: AddressFormErrors = {};
      missingFields.forEach(field => {
        errors[field[0]] = `Please enter a valid ${field[1]}`;
      });

      setErrors(errors);
      return;
    }

    if (!emailRegex.test(value.email)) {
      setErrors({
        email: 'Please enter a valid email',
      });
      return;
    }

    setErrors({});
    onSubmit(value);
  }, [value, setErrors, onSubmit]);

  const handleNameChange = useFieldOnChange(value, onChange, 'fullName', clearError);
  const handleLine1Change = useFieldOnChange(value, onChange, 'line1', clearError);
  const handleLine2Change = useFieldOnChange(value, onChange, 'line2', clearError);
  const handleCityChange = useFieldOnChange(value, onChange, 'city', clearError);
  const handleZipChange = useFieldOnChange(value, onChange, 'zip', clearError);
  const handleEmailChange = useFieldOnChange(value, onChange, 'email', clearError);
  const handleStateChange = useFieldOnChange(value, onChange, 'state', clearError);
  const handleNotesChange = useFieldOnChange(value, onChange, 'notes', clearError);

  const handleCountryChange = useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => {
    onChange({
      ...value,
      country: ev.target.value,
      state: '',
    });
    const stateOptions = (getStates(ev.target.value) as string[]).map(state => ({ label: state, value: state }));
    stateOptions.unshift(statePlaceholder);
    setStatesOptions(stateOptions)
    clearError('country');
  }, [value, onChange, clearError, setStatesOptions]);

  return (<div>
      <ModalHeader title="Congratulations Citizen">
        Welcome $CITIZEN{nftNumber && ` #${nftNumber}`}! Complete your shipping information below to recieve your Passport.
      </ModalHeader>
      <div>
        <TextInput placeholder="Full Name" value={value.fullName} onChange={handleNameChange} className={styles.formRow} error={errors.fullName} />
        <TextInput placeholder="Address Line 1"  value={value.line1} onChange={handleLine1Change} className={styles.formRow} error={errors.line1} />
        <TextInput placeholder="Address Line 2"  value={value.line2} onChange={handleLine2Change} className={styles.formRow} error={errors.line2} />
        <div className={styles.splitRow}>
          <TextInput placeholder="City"  value={value.city} onChange={handleCityChange} error={errors.city} />
          <Select options={statesOptions} value={value.state} placeholder="State" onChange={handleStateChange} error={errors.state} />
        </div>
        <div className={styles.splitRow}>
          <TextInput placeholder="Zip"  value={value.zip} onChange={handleZipChange} error={errors.zip} />
          <Select options={countries.map(country => ({ label: country.name, value: country.code }))} value={value.country} onChange={handleCountryChange} error={errors.country} />
        </div>
        <TextInput placeholder="Email Address"  value={value.email} onChange={handleEmailChange} className={styles.formRow} error={errors.email} />
        <TextArea placeholder="Notes" value={value.notes} onChange={handleNotesChange} className={styles.formRow} error={errors.notes} />
      </div>

      <Button small className={styles.submitButton} onClick={handleSubmit} >Sign &amp; Submit</Button>

      {submitError && <p className={styles.error}>{submitError}</p>}

    </div>);
};

export default AddressForm;