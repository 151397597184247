import React from "react";
import classNames from "classnames";

interface IProps {
  color?: "green" | "line-green" | "line-white";
  className?: string;
  children: React.ReactNode;
  fullWidth?: boolean;
  href?: string;
  onClick?: any;
  external?: boolean;
}

export default function Button({
  className,
  children,
  color = "green",
  fullWidth,
  href,
  onClick,
  external,
}: IProps) {
  if (onClick) {
    return (
      <button
        onClick={onClick}
        className={classNames("button", color, { fullWidth }, className)}
      >
        {children}
      </button>
    );
  }

  if (!href) {
    return (
      <span className={classNames("button", color, { fullWidth }, className)}>
        {children}
      </span>
    );
  }

  return (
    <a
      href={href}
      target={external ? "_blank" : undefined}
      className={classNames("button", color, { fullWidth }, className)}
    >
      {children}
    </a>
  );
}
