import React from 'react'
import Button from './Button'

export default function HomeHero() {
  return (
    <div className="home-hero">
      <div className="width">
        <div className="home-hero-flex">
          <h1>Sovereignty starts at silicon.</h1>

          <div className="home-hero-text">
            <p>
              Public key cryptography and distributed networks now allow for direct human coordination, free from
              moderation by centralized institutions.
            </p>

            <div className="home-hero-buttons">
              <Button href="https://app.uniswap.org/#/swap?exactField=output&exactAmount=1&outputCurrency=0x77F0cc420dEa0aE726Db6Bef1460A4B69176A8Ea&chain=mainnet">
                Acquire Citzenship
              </Button>
              <Button href="https://discord.gg/dypeg4JfTX" color="line-green">
                Discord
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
