import React from "react";
import HomeCard from "./HomeCard";

interface IProps {
  onRedeem: any;
}

export default function HomeCards({ onRedeem }: IProps) {
  return (
    <div className="home-cards">
      <div className="width">
        <div className="home-cards-grid">
          <HomeCard
            className="small"
            href="https://medium.com/kong-land-embassy/welcome-to-kong-land-205a7772378f"
            subtext="History"
            title="Background"
            linkText="View post"
          >
            Kong is only the first of many metanations. We interact with the
            real world: we have diplomatic relations, economic covenants, state
            functions, and social services.
          </HomeCard>

          <HomeCard
            bg="/images/new/made-bg.jpg"
            image="/images/new/made-in-kongland.png"
            className="flex"
            href="https://kongland.gitbook.io/home/"
            subtext="Exports"
            title={
              <>
                Made in
                <br />
                Kong Land
              </>
            }
            linkText="View projects"
            imageWidth={500}
            tagColor="blue"
          >
            Goods embedding KONG Land technology including HaLo and SiLo chips.
          </HomeCard>

          <HomeCard
            bg="/images/new/halo-bg.jpg"
            image="/images/new/our-tech.png"
            className="flex"
            href="https://arx.org"
            subtext="Tech"
            title="The Tech"
            linkText="Learn more"
            imageWidth={220}
            tagColor="orange"
          >
            Embed HaLo chips in merch, art, collectables and more to link them
            to NFTs, tokens and experiences.
          </HomeCard>

          <HomeCard
            className="small"
            href="https://charter.kong.land/charter"
            subtext="History"
            title="Charter"
            linkText="Sign"
          >
            The Founding Charter of KONG Land. $CITIZENs can sign the Charter to
            participate in governance.
          </HomeCard>

          <HomeCard
            className="flex"
            href="https://app.uniswap.org/#/swap?exactField=output&exactAmount=1&outputCurrency=0x77F0cc420dEa0aE726Db6Bef1460A4B69176A8Ea&chain=mainnet"
            subtext="Citizen"
            title="Get $Citizen"
            linkText="Acquire"
            image="/images/new/aquire.png"
            imageWidth={300}
            bg="/images/new/get-bg.jpg"
            tagColor="purple"
            onRedeem={onRedeem}
          >
            Join KONG Land by acquiring and burning $CITIZEN. Citizens direct
            and build the crypto state.
          </HomeCard>

          <HomeCard
            className="small"
            href="https://snapshot.org/#/kong.eth"
            subtext="Citizen"
            title="Snapshot"
            linkText="Vote on snapshot"
            tagColor="purple"
          >
            View, create and participate in KONG Land governance proposals as a
            $CITIZEN.
          </HomeCard>
        </div>
      </div>
    </div>
  );
}
