import { TransactionResponse } from "@ethersproject/abstract-provider";
import { ethers } from "ethers";
import { getContractWithSigner, config } from './config';

let hasConnected = false;

const approve = async () => {
  if (!hasConnected) {
    await window.ethereum.request({ method: 'eth_requestAccounts' })
    hasConnected = true;
  }

  const { contract } = getContractWithSigner('citizen');
  const response = await (contract.approve(config.burnmint.address, ethers.utils.parseUnits('1.0', 18)) as Promise<TransactionResponse>);

  await response.wait(1);
  
  return response;
};

export default approve;