import React from "react";
import cx from 'classnames'
import format from 'date-fns/format';
import isThisYear from 'date-fns/isThisYear';
import styles from './ActivityPanel.module.scss';
import { ActivityEntry } from "../../crypto/getActivity";

interface ActivityPanelProps {
  className?: string;
  items: ActivityEntry[];
}

const activityIcons: { [key in ActivityEntry['type']]: string } = {
  'minted': 'images/activity/minted.svg',
  'event': 'images/activity/event.svg',
  'vote': 'images/activity/vote.svg',
  'transfer': 'images/activity/transfer.svg',
};

const formatDate = (date: Date) => isThisYear(date) ? format(date, 'LLL d') : format(date, 'LLL d, yyyy');

const ActivityPanel: React.FC<ActivityPanelProps> = ({ className, items }) => {

  return (<div className={cx(styles.container, className)}>
      <p className={styles.title} >Activity</p>
      <div className={styles.track}>
        <div className={styles.trackStop}>No more activity to show</div>
        <div className={styles.trackItems}>
        {
          items.map((item) => (<div key={item.timestamp} className={styles.item}>
            <div className={styles.image} style={{ backgroundImage: `url(${activityIcons[item.type]})` }} />
            <p>{item.label} <span>{formatDate(new Date(item.timestamp * 1000))}</span></p>
          </div>))
        }
        </div>
      </div>
    </div>);
};

export default ActivityPanel;