import { FirebaseError } from '@firebase/util';
import { Timestamp, collection, query, getDocs, doc as fiDoc, getDoc, onSnapshot } from 'firebase/firestore';
import firestore from './firestore';

export interface POAPData {
  id: string;
  external_url: string;
  image_url: string;
  name: string;
}
export interface TokenObject {
  id: number;
  name: string;
  image: string;
  description: string;
  attributes: ({ trait_type: string, value: string })[];
  owner: string;
  poaps: POAPData[];
  claimedName?: string;
  bio?: string;
}


export interface TokenCacheEntry extends TokenObject {
  createdAt: Timestamp;
}


export const getCachedToken = async (tokenId: number) => {
  const entryQuery = fiDoc(firestore, 'tokenCache', tokenId.toString());

  const doc = await getDoc(entryQuery);

  const entry: TokenCacheEntry = {
    ...(doc.data() as TokenCacheEntry),
  };

  return entry;
};

export const getCachedTokens = async () => {
  const q = query(collection(firestore, 'tokenCache'));

  const results = await getDocs(q);

  return results.docs.map((res) => res.data() as TokenCacheEntry)
}


export const followToken = (tokenId: number, next: (val: TokenCacheEntry) => void, onErr: (err: FirebaseError) => void) => {
  const tokenRef = fiDoc(firestore, 'tokenCache', tokenId.toString());

  // Typescript is not picking up the correct override, no idea why
  // @ts-ignore
  return onSnapshot(tokenRef, {
    next(snapshot) {
      return next(snapshot.data() as TokenCacheEntry);
    },
    error: onErr,
  });
}