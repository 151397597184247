

export interface StateShape {
  address: string;
  ownedTokenId: string;
}

type StateChangeCallback = (state: StateShape) => void;

class AppState {
  private _state: StateShape;
  private changeCallbacks: StateChangeCallback[];

  constructor() {
    this._state = {
      address: '',
      ownedTokenId: '',
    };
    this.changeCallbacks = [];
  }

  onChange(cb: StateChangeCallback) {
    this.changeCallbacks.push(cb);
    cb(this._state);
  }

  offChange(cb: StateChangeCallback) {
    const cbInd = this.changeCallbacks.indexOf(cb);
    if (cbInd !== -1) {
      this.changeCallbacks.splice(cbInd, 1);
    }
  }

  private emitChange() {
    this.changeCallbacks.forEach((cb) => {
      cb(this._state);
    });
  }

  get address(): string {
    return this._state.address;
  }

  set address(val: string) {
    this._state.address = val;
    this.emitChange();
  }

  get ownedTokenId(): string {
    return this._state.ownedTokenId;
  }

  set ownedTokenId(val: string) {
    this._state.ownedTokenId = val;
    this.emitChange();
  }
}


export default new AppState();