import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../svg/arrow.svg";
import Tilt from "react-parallax-tilt";
import Button from "./Button";

interface IProps {
  subtext: string;
  title: string | React.ReactNode;
  children?: React.ReactNode;
  linkText: string;
  href: string;
  image?: string;
  className?: string;
  imageWidth?: number;
  tagColor?: "green" | "orange" | "red" | "purple" | "blue";
  bg?: string;
  onRedeem?: any;
}

export default function HomeCard({
  subtext,
  title,
  children,
  linkText,
  href,
  image,
  className,
  imageWidth,
  bg,
  tagColor = "green",
  onRedeem,
}: IProps) {
  const handleRedeem = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    onRedeem();
  };

  return (
    <>
      <Tilt
        style={{ backgroundImage: `url(${bg})` }}
        className={classNames("home-card", className)}
        tiltMaxAngleX={3}
        tiltMaxAngleY={3}
      >
        <a href={href} className="home-card-inner">
          <span className={classNames("home-card-subtext", tagColor)}>
            {subtext}
          </span>

          <h2 className="home-card-title">{title}</h2>

          {image && (
            <span className="home-card-image">
              <div className="home-card-image-relative">
                <img style={{ maxWidth: imageWidth }} src={image} alt="title" />
              </div>
            </span>
          )}

          <p className="home-card-p">{children}</p>

          <div className="home-card-buttons">
            <Button color="line-white">
              <span>{linkText}</span>
              <Arrow />
            </Button>

            {title === "Charter" && (
              <Button
                external
                color="line-white"
                href="https://ipfs.io/ipfs/QmXd8HV6yK87M7fmszaXRULpM9cf1pMEuAg25UKskozACk"
              >
                <span>Una</span>
                <Arrow />
              </Button>
            )}

            {onRedeem && (
              <Button color="line-white" onClick={handleRedeem}>
                <span>Redeem</span>
                <Arrow />
              </Button>
            )}
          </div>

          <span className="home-card-linktext"></span>
        </a>
      </Tilt>
    </>
  );
}
