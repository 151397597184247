import React, { useState, useCallback } from "react";
import cx from "classnames";
import Button from "./Button";
import Modal from "./Modal";

import styles from "./RedeemModal.module.scss";
import AddressForm, { AddressFormShape } from "./AddressForm";
import ModalHeader from "./ModalHeader";
import ConfirmationScreen from "./ConfirmationScreen";
import approve from "../crypto/approve";
import {
  TransactionReceipt,
  TransactionResponse,
} from "@ethersproject/abstract-provider";
import burn, { getTokenFromReceipt } from "../crypto/burn";
import createOrder from "../firebase/orders";
import { signMessage } from "../crypto/config";

interface RedeemModalProps {
  children?: React.ReactNode;
  open: boolean;
  onClose: () => void;
  onPuristFork: () => void;
}

enum RedemptionStep {
  PendingApprove = "PendingApprove",
  Approving = "Approving",
  PendingBurn = "PendingBurn",
  Burning = "Burning",
  Address = "Address",
  Confirmation = "Confirmation",
}

interface RedeemButtonState {
  copy: string;
  outline?: boolean;
  disabled?: boolean;
  loading?: boolean; // show loading spinner
  completed?: boolean; // show checkmark on lhs
}

const redeemButtonStates: {
  [step in RedemptionStep]?: {
    top: RedeemButtonState;
    bottom: RedeemButtonState;
  };
} = {
  [RedemptionStep.PendingApprove]: {
    top: { copy: "Approve $CITIZEN to burn" },
    bottom: { copy: "Burn $CITIZEN to claim", outline: true },
  },
  [RedemptionStep.Approving]: {
    top: { copy: "Pending", loading: true, disabled: true },
    bottom: { copy: "Burn $CITIZEN to claim", outline: true },
  },
  [RedemptionStep.PendingBurn]: {
    top: { copy: "Approval Sent", disabled: true, completed: true },
    bottom: { copy: "Burn $CITIZEN to claim" },
  },
  [RedemptionStep.Burning]: {
    top: { copy: "Approval Sent", disabled: true, completed: true },
    bottom: { copy: "Pending", loading: true, disabled: true },
  },
};

interface RedeemButtonProps {
  buttonState: RedeemButtonState | undefined;
  onClick: (ev: React.MouseEvent) => void;
}

const RedeemButton: React.FC<RedeemButtonProps> = ({ buttonState, onClick }) =>
  buttonState ? (
    <Button
      disabled={buttonState.disabled}
      outline={buttonState.outline}
      onClick={onClick}
      className={cx(
        buttonState.loading && styles.loadingButton,
        buttonState.completed && styles.completedButton
      )}
    >
      {buttonState.copy}
      {buttonState.loading && (
        <img src="/images/spinner.svg" className={styles.spinner} alt="" />
      )}
    </Button>
  ) : null;

const maxFallbacks = 4;
type handleTransactionErrorType = (
  err: any,
  setError: (val: string) => void,
  nextStep: RedemptionStep,
  prevStep: RedemptionStep,
  fallbacks?: number
) => Promise<{ step: RedemptionStep; receipt?: TransactionReceipt }>;
const handleTransactionError: handleTransactionErrorType = async (
  err,
  setError,
  nextStep,
  prevStep,
  fallbacks = 0
) => {
  // docs on possible errors: https://docs.ethers.io/v5/api/providers/types/#providers-TransactionResponse)
  if (err.code === "INVALID_ARGUMENT") {
    setError(
      "Connection to MetaMask is required to approve burning your CITIZEN."
    );
  } else if (err.code === "TRANSACTION_REPLACED") {
    if (err.reason === "cancelled" || !err.replacement) {
      setError("Your transaction was cancelled, please try again.");
      return { step: prevStep };
    }

    // Try to wait until replacement transaction is returned, move forward if successful
    const transactionResult: TransactionResponse = err.replacement;
    try {
      const transactionReceipt = await transactionResult.wait(1);
      return { step: nextStep, reciept: transactionReceipt };
    } catch (innerError) {
      if (fallbacks < maxFallbacks) {
        return handleTransactionError(
          innerError,
          setError,
          nextStep,
          prevStep,
          fallbacks + 1
        );
      }
      setError("There was an unexpected error processing your transaction.");
    }
  } else if (err.code === "UNSUPPORTED_OPERATION") {
    setError("You must log in to Metamask before attempting to burn.");
  } else {
    setError("There was an unexpected error processing your transaction.");
  }
  return { step: prevStep };
};

const RedeemModal: React.FC<RedeemModalProps> = ({
  open,
  onClose,
  onPuristFork,
}) => {
  const [step, setStep] = useState<RedemptionStep>(
    RedemptionStep.PendingApprove
  );
  const [address, setAddress] = useState<AddressFormShape>();
  const [error, setError] = useState<string>();
  const [mintedNFT, setMintedNFT] = useState<string>("");
  const [recipientAddress, setRecipientAddress] = useState<string>("");

  const handleApproveClick = useCallback(async () => {
    if (step !== RedemptionStep.PendingApprove) {
      return;
    }
    setError("");
    setStep(RedemptionStep.Approving);

    let transactionResult: TransactionResponse;

    if (typeof window.ethereum === "undefined") {
      setError(
        "Connection to MetaMask is required to approve burning your CITIZEN."
      );
      setStep(RedemptionStep.PendingApprove);
      return;
    }

    try {
      transactionResult = await approve();
    } catch (err) {
      console.error(err);
      const { step: nextStep } = await handleTransactionError(
        err,
        setError,
        RedemptionStep.PendingBurn,
        RedemptionStep.PendingApprove
      );

      setStep(nextStep);
      return;
    }

    setStep(RedemptionStep.PendingBurn);
  }, [setStep, step, setError]);

  const handleBurnClick = useCallback(async () => {
    if (step !== RedemptionStep.PendingBurn) {
      return;
    }
    setError("");
    setStep(RedemptionStep.Burning);

    try {
      const { token, ethAddress } = await burn();
      setMintedNFT(token);
      setRecipientAddress(ethAddress);
    } catch (err) {
      const { step: nextStep, receipt } = await handleTransactionError(
        err,
        setError,
        RedemptionStep.Address,
        RedemptionStep.PendingBurn
      );

      if (receipt) {
        try {
          const { token, ethAddress } = getTokenFromReceipt(receipt);
          setMintedNFT(token);
          setRecipientAddress(ethAddress);
        } catch (err) {
          setError(
            "An unexpected error happened while processing your transaction"
          );
          setStep(RedemptionStep.PendingBurn);
          return;
        }
      }

      setStep(nextStep);
      return;
    }

    setStep(RedemptionStep.Address);
  }, [setStep, setError, setMintedNFT, setRecipientAddress, step]);

  const handleAddressSubmit = useCallback(
    async (addressForm: AddressFormShape) => {
      setAddress(addressForm);
      setError("");

      const timestampDate = new Date();
      try {
        const signature = await signMessage({
          ETH_ADDRESS: recipientAddress,
          NAME: addressForm.fullName,
          TOKEN_ID: mintedNFT,
          TIMESTAMP: timestampDate.toISOString(),
        });

        await createOrder({
          address: addressForm,
          tokenId: mintedNFT,
          recipientAddress,
          signature,
          timestamp: timestampDate,
        });
      } catch (err) {
        setError("An unexpected error happened while signing your token.");
        setStep(RedemptionStep.Address);
        return;
      }

      setStep(RedemptionStep.Confirmation);
    },
    [setStep, setError, mintedNFT, recipientAddress, setAddress]
  );

  const buttonStates =
    redeemButtonStates[step] ||
    redeemButtonStates[RedemptionStep.PendingApprove];

  const resetStateAndClose = useCallback(() => {
    setAddress(undefined);
    setStep(RedemptionStep.PendingApprove);
    onClose();
  }, [onClose, setStep, setAddress]);

  let modalContent;

  switch (step) {
    case RedemptionStep.Address:
      modalContent = (
        <AddressForm
          onSubmit={handleAddressSubmit}
          nftNumber={mintedNFT.padStart(4, "0")}
          submitError={error}
        />
      );
      break;
    case RedemptionStep.Confirmation:
      modalContent = (
        <ConfirmationScreen
          address={address}
          nftTokenId={mintedNFT}
          onClose={resetStateAndClose}
        />
      );
      break;
    default:
      modalContent = (
        <>
          <ModalHeader title="Redeem Citizen">
            If you have already minted your ERC-721 you{" "}
            <a onClick={onPuristFork}>can request to update your address</a> if
            we haven’t shipped yet Your $CITIZEN NFT will be revealed with the
            physical KONG Land Passport.
          </ModalHeader>

          <div className={styles.buttons}>
            <RedeemButton
              buttonState={buttonStates?.top}
              onClick={handleApproveClick}
            />
            <RedeemButton
              buttonState={buttonStates?.bottom}
              onClick={handleBurnClick}
            />
          </div>
          {error && <p className={styles.error}>{error}</p>}
        </>
      );
      break;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={styles.redeemModal}
      closeable={
        ![RedemptionStep.Address, RedemptionStep.Confirmation].includes(step)
      }
    >
      {modalContent}
    </Modal>
  );
};

export default RedeemModal;
