import cx from 'classnames';
import { useCallback } from 'react';

import styles from './Modal.module.scss';

interface ModalProps {
  children?: React.ReactNode;
  className?: string;
  overlayClassName?: string;
  open: boolean;
  onClose: () => void;
  closeable?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  children, open, onClose, className, overlayClassName, closeable,
}) => {
  const handleOverlayClick = useCallback((ev: React.MouseEvent) => {
    if (closeable && !(ev.target as Element).closest('#modal')) {
      onClose();
    }
  }, [onClose, closeable]);

  return (
    <div className={cx(styles.overlay, open && styles.open, overlayClassName)} onClick={handleOverlayClick}>
      <div className={cx(styles.modal, className)} id="modal">
      {closeable && <img src="/images/closeX.svg" className={styles.closeButton} onClick={onClose} alt="Close" />}
        {children}
      </div>
    </div>
  );
};

export default Modal;
