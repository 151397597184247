import { Event } from "@ethersproject/contracts";
import { getContractWithSigner } from "./config";

export interface ActivityEntry {
  timestamp: number;
  type: 'minted' | 'transfer' | 'vote' | 'event';
  label: string;
}

const zeroAddr = '0x0000000000000000000000000000000000000000';
const getEventTimestamp = (event: Event) => {
  return event.getBlock().then((block) => block.timestamp);
}

const getActivity = async (tokenId: number) => {
  const { contract } = getContractWithSigner('erc721');

  const filter = contract.filters.Transfer(null, null, tokenId);

  const results = await contract.queryFilter(filter);

  const actEntries: ActivityEntry[] = [];
  for (const evt of results) {
    const isMint = evt.args?.from === zeroAddr;
    actEntries.push({
      timestamp: await getEventTimestamp(evt),
      type: isMint ? 'minted' : 'transfer',
      label: isMint ? 'Minted NFT' : `Transferred from ${evt.args?.from} to ${evt.args?.to}`,
    })
  }

  actEntries.sort((a, b) => a.timestamp - b.timestamp);

  return actEntries;
};

export default getActivity;