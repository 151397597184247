import React, { useCallback, useEffect, useState } from "react";
import cx from 'classnames';
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import GalleryHeader from "./components/gallery/GalleryHeader";
import { followToken, getCachedToken, getCachedTokens, TokenObject } from "./firebase/tokenCache";

import styles from './GalleryDetails.module.scss';
import Button from "./components/Button";
import BadgeGrid from "./components/gallery_details/BadgeGrid";
import ActivityPanel from "./components/gallery_details/ActivityPanel";
import { config } from "./crypto/config";
import TokenTile from "./components/gallery/TokenTile";
import appState, { StateShape } from "./AppState";
import ClaimModal from "./components/gallery_details/ClaimModal";
import BioModal from "./components/gallery_details/BioModal";
import getActivity, { ActivityEntry } from "./crypto/getActivity";

interface GalleryDetailsProps extends RouteComponentProps<{ id: string }> {
  children?: React.ReactNode;
}
const GalleryDetails: React.FC<GalleryDetailsProps> = ({ match, history }) => {
  const [ready, setReady] = useState<boolean>(false);
  const [tokenObj, setTokenObj] = useState<TokenObject>();
  const [moreCitizens, setMoreCitizens] = useState<TokenObject[]>([]);
  const [claimModalOpen, setClaimModalOpen] = useState<boolean>(false);
  const [bioModalOpen, setBioModalOpen] = useState<boolean>(false);

  const [activity, setActivity] = useState<ActivityEntry[]>([]);

  const [ownedTokenId, setOwnedTokenId] = useState<string>('');

  const toggleClaimModal = useCallback(() => {
    setClaimModalOpen(!claimModalOpen);
  }, [claimModalOpen, setClaimModalOpen]);

  const toggleBioModal = useCallback(() => {
    setBioModalOpen(!bioModalOpen);
  }, [bioModalOpen, setBioModalOpen]);


  useEffect(() => {
    const id = parseInt(match.params.id, 10);

    const unsubscribe = followToken(id, (val) => setTokenObj(val), err => {});
    
    getCachedToken(id).then((token) => {
      if (token) {
        setTokenObj(token);
        setTimeout(setReady, 250, true);
      } else {
        history.goBack();
      }
    }).catch(err => {
      history.goBack();
    });

    getCachedTokens().then((tokens) => {
      setMoreCitizens(tokens);
    });

    setActivity([]);
    getActivity(id).then((entries) => {
      setActivity(entries);
    });

    return unsubscribe;

  }, [history.location.pathname]);

  useEffect(() => {
    const cb = (state: StateShape) => {
      setOwnedTokenId(state.ownedTokenId);
    };

    appState.onChange(cb);

    return () => {
      appState.offChange(cb);
    }
  }, [setOwnedTokenId]);

  const tokenTiles = moreCitizens
    .filter(token => token.id !== tokenObj?.id)
    .map(token => (
      <TokenTile token={token} key={token.id} />
    ));
  const placeholderTiles = new Array(12 - 0 % 12).fill((<div className={styles.placeholder} />));

  const tokensContent = tokenTiles.length > 0 ? <>{tokenTiles}{placeholderTiles}</> : <div className={styles.emptyPlaceholder} />;

  return (<>
        {!ready && (
        <div className="loading-splash">
          <div>
            <div className="spinner"></div>
          </div>
        </div>
      )}
  <div className={cx('wrapper', ready && 'ready', styles.detailsWrapper)}>
    <GalleryHeader/>
    <div className={styles.main}>
      <div className={styles.info}>
        <div className={styles.infoContent}>
          <h1>{tokenObj?.claimedName || 'Unknown'}<br/><small>.citizen.eth</small></h1>
          <p>{tokenObj?.bio}</p>
          {
            appState.address.toLowerCase() === tokenObj?.owner?.toLowerCase() && ownedTokenId && tokenObj.claimedName && !tokenObj?.bio &&
            (<a className={styles.infoSocialLink} onClick={toggleBioModal}>Add Bio </a>)
          }
          {
            appState.address.toLowerCase() === tokenObj?.owner?.toLowerCase() && ownedTokenId &&
            <div className={styles.splitRow}>
              <Button small className={styles.profileButton} onClick={toggleClaimModal}>Claim Eth address</Button>
              <Button small className={styles.profileButton} disabled={!tokenObj.claimedName} title={!tokenObj.claimedName ? 'You must claim a name before setting this token\'s bio' : ''} onClick={toggleBioModal}>Update Profile</Button>
          </div>
          }
          <a href={`https://opensea.io/assets/${config.erc721.address}/${tokenObj?.id}`} target="_blank" rel="noopener noreferrer">
            <Button small className={cx(styles.profileButton, styles.whiteWide)}>View on Opensea</Button>
          </a>
        </div>
        <BadgeGrid images={tokenObj?.poaps.map(poap => ({ url: poap.image_url, title: poap.name })) || []} className={styles.badgeGrid} />
      </div>
      <div className={styles.image} style={{ backgroundImage: `url(${tokenObj?.image})` }}></div>
      <ActivityPanel className={styles.activity} items={activity} />
    </div>
    <div>
      <h2 className={styles.tokensTitle}>More Citizens</h2>
      <div className={styles.tokens}>
          <div className={styles.tokensInner}>
            {
              tokensContent
            }          
          </div>
        </div>
    </div>
  </div>
  <ClaimModal open={claimModalOpen} onClose={toggleClaimModal} claimedName={tokenObj?.claimedName} />
  <BioModal open={bioModalOpen} onClose={toggleBioModal} claimedName={tokenObj?.claimedName || ''} />
  </>);
};

export default GalleryDetails;