import React, { DetailedHTMLProps, InputHTMLAttributes, SelectHTMLAttributes, TextareaHTMLAttributes } from "react";
import cx from 'classnames';

import styles from './Inputs.module.scss';

interface CustomInputProps {
  error?: string;
  inputClassName?: string
}

type TextInputProps = React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & CustomInputProps;

export const TextInput: React.FC<TextInputProps> = ({ className, inputClassName, error, ...rest }) => (
  <div className={cx(styles.container, className)}>
    <input type="text" {...rest} className={cx(styles.input, inputClassName)} />
    {error && <p className={styles.error}>{error}</p>}
  </div>
);

type TextAreaProps = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & CustomInputProps;

export const TextArea: React.FC<TextAreaProps> = ({ className, inputClassName, error, ...rest }) => (
  <div className={cx(styles.container, className)}>
    <textarea {...rest} className={cx(styles.input, inputClassName)} ></textarea>
    {error && <p className={styles.error}>{error}</p>}
  </div>
);

interface SelectOption {
  label: string;
  value: string;
}

type SelectProps = React.DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & CustomInputProps & {
  options: SelectOption[];
};

export const Select: React.FC<SelectProps> = ({ options, className, inputClassName, onSelect, error, ...rest }) => (
  <div className={cx(styles.selectContainer, className)}>
    <select {...rest} className={cx(styles.select, inputClassName)} required>
      {
        options.map(opt => (
          <option value={opt.value} disabled={!opt.value}>
            {opt.label}
          </option>
        ))
      }
    </select>
    <div className={styles.caret} />
    {error && <p className={styles.error}>{error}</p>}
  </div>
);