import $ from "jquery";
import { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import useWindow from "./hooks/useWindow";

import { ethers, BigNumber } from "ethers";
import { Pool } from "@uniswap/v3-sdk";
import { Token } from "@uniswap/sdk-core";
import { abi as IUniswapV3PoolABI } from "@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json";
import { useViewportScroll } from "framer-motion";
import RedeemModal from "./components/RedeemModal";
import { useCallback } from "react";
import PuristModal from "./components/PuristModal";
import { Link } from "react-router-dom";
import Header from "./components/new/Header";
import HomeCards from "./components/new/HomeCards";
import HomeHero from "./components/new/HomeHero";
import Footer from "./components/new/Footer";
import classNames from "classnames";

const provider = new ethers.providers.JsonRpcProvider(
  "https://mainnet.infura.io/v3/86e5f335952c4a86b17b92fe3e178a2b"
);

const poolAddress = "0xf5d90ce04151979d0a7c8eaced8ed1419e190477";

const poolContract = new ethers.Contract(
  poolAddress,
  IUniswapV3PoolABI,
  provider
);

interface Immutables {
  factory: string;
  token0: string;
  token1: string;
  fee: number;
  tickSpacing: number;
  maxLiquidityPerTick: ethers.BigNumber;
}

interface State {
  liquidity: ethers.BigNumber;
  sqrtPriceX96: ethers.BigNumber;
  tick: number;
  observationIndex: number;
  observationCardinality: number;
  observationCardinalityNext: number;
  feeProtocol: number;
  unlocked: boolean;
}

async function getPoolImmutables() {
  const immutables: Immutables = {
    factory: await poolContract.factory(),
    token0: await poolContract.token0(),
    token1: await poolContract.token1(),
    fee: await poolContract.fee(),
    tickSpacing: await poolContract.tickSpacing(),
    maxLiquidityPerTick: await poolContract.maxLiquidityPerTick(),
  };
  return immutables;
}

async function getPoolState() {
  const slot = await poolContract.slot0();
  const PoolState: State = {
    liquidity: await poolContract.liquidity(),
    sqrtPriceX96: slot[0],
    tick: slot[1],
    observationIndex: slot[2],
    observationCardinality: slot[3],
    observationCardinalityNext: slot[4],
    feeProtocol: slot[5],
    unlocked: slot[6],
  };
  return PoolState;
}

async function main() {
  const immutables = await getPoolImmutables();
  const state = await getPoolState();
  const TokenA = new Token(
    1,
    immutables.token0,
    18,
    "CITIZEN",
    "Kong Land Citizenship"
  );
  const TokenB = new Token(1, immutables.token1, 18, "WETH", "Wrapped Ether");
  const poolExample = new Pool(
    TokenA,
    TokenB,
    immutables.fee,
    state.sqrtPriceX96.toString(),
    state.liquidity.toString(),
    state.tick
  );
  // console.log((BigNumber.toNumber(state.liquidity)).divide(poolExample.priceOf(TokenB).toFixed()).toString())
  console.log(poolExample.priceOf(TokenA).toFixed());
  return poolExample.priceOf(TokenA).toFixed(2);
}

function App() {
  const [buyButtonActive, setBuyButtonActive] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const [sticky, setSticky] = useState<boolean>(false);
  const [price, setPrice] = useState<string>("");
  const [redeemOpen, setRedeemOpen] = useState<boolean>(false);
  const [puristOpen, setPuristOpen] = useState<boolean>(false);

  const fixedDiv = useRef<HTMLDivElement | null>(null);
  const scrollableDiv = useRef<HTMLDivElement | null>(null);

  const { scrollY } = useViewportScroll();

  const toggleRedeem = useCallback(() => {
    setRedeemOpen(!redeemOpen);
  }, [setRedeemOpen, redeemOpen]);

  const togglePurist = useCallback(() => {
    setPuristOpen(!puristOpen);
  }, [setPuristOpen, puristOpen]);

  const handlePuristFork = useCallback(() => {
    setRedeemOpen(false);
    setPuristOpen(true);
  }, [setRedeemOpen, setPuristOpen]);

  useEffect(() => {
    main().then((res) => {
      setPrice(res);
    });

    getPoolImmutables().then((result) => {
      setReady(true);
      console.log("etheres result:");
      console.log(result);
    });

    // todo: remove jquery dep
    // attach event listener
    // $(".right-faq-question header").each(function () {
    //   $(this).next().slideToggle();
    //   $(this).parent().toggleClass("active");
    // });

    type a = JQuery.TypeEventHandler<any, any, any, any, any>;
    const toggleHandler: a = function () {
      $(this).next().slideToggle();
      $(this).parent().toggleClass("active");
    };

    $(".right-faq-question header").on("click", toggleHandler);

    // attach klaviyoSubscribe
    (window as any)["KlaviyoSubscribe"]?.attachToForms?.("#signup", {
      hide_form_on_success: true,
    });

    // mobile buy button scroll
    scrollY.onChange(() => {
      let y = scrollY.get();
      setBuyButtonActive(y > 700);
    });

    // mousewheel
    const scrollHandler = (e: any) => {
      if (scrollableDiv.current) {
        scrollableDiv.current.scrollTop += e.deltaY;
      }
    };
    fixedDiv.current?.addEventListener("mousewheel", scrollHandler);

    // end of effect
    return () => {
      // clean up
      fixedDiv.current?.removeEventListener("mousewheel", scrollHandler);
      $(".right-faq-question header").off("click", toggleHandler);
    };
  }, []);

  return (
    <>
      {!ready && (
        <div className="loading-splash">
          <div>
            <div className="spinner"></div>
          </div>
        </div>
      )}

      <div className={classNames("wrapper", { ready })}>
        <img
          className="blurs"
          src="/images/new/blurs.png"
          alt="Blur gradients"
        />

        <Header />

        <HomeHero />
        <HomeCards onRedeem={toggleRedeem} />
        <Footer />

        <RedeemModal
          open={redeemOpen}
          onClose={toggleRedeem}
          onPuristFork={handlePuristFork}
        />

        <PuristModal open={puristOpen} onClose={togglePurist} />
      </div>
    </>
  );
}

export default App;
