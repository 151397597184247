import { initializeApp } from 'firebase/app';
/* Uncomment commented lines to use local emulators */
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const app = initializeApp({
  apiKey: "AIzaSyDhxZUWsXkBAAN5O7Mf5lk3-752OWP07rw",
  authDomain: "kong-land.firebaseapp.com",
  projectId: "kong-land",
  storageBucket: "kong-land.appspot.com",
  messagingSenderId: "348719348595",
  appId: "1:348719348595:web:786e60cbf3c727d0e7e306"
});


export const functions = getFunctions(app);
connectFunctionsEmulator(functions, 'localhost', 5001);



export default app;