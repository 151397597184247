import React, { ButtonHTMLAttributes } from "react";
import cx from 'classnames';

import styles from './Button.module.scss';

type ButtonProps = React.DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & { outline?: boolean, small?: boolean };

const Button: React.FC<ButtonProps> = ({ className, children, outline, small, ...props }) => {

  return (<button type="button" {...props} className={cx(styles.button, outline && styles.outline, small && styles.small, className)} >
    {children}
  </button>);
};

export default Button;