import React, { useCallback, useEffect, useState } from 'react';
import { signMessage } from '../crypto/config';
import getHasToken from '../crypto/hasToken';
import createOrder from '../firebase/orders';
import AddressForm, { AddressFormShape } from './AddressForm';
import ConfirmationScreen from './ConfirmationScreen';
import Modal from './Modal';

import redeemStyles from './RedeemModal.module.scss';

interface PuristModalProps {
  open: boolean;
  onClose: () => void;
}

enum PuristStep {
  Address = 'Address',
  Confirmation = 'Confirmation',
}

const PuristModal: React.FC<PuristModalProps> = ({ open, onClose }) => {
  const [hasToken, setHasToken] = useState<boolean>(false);
  const [address, setAddress] = useState<AddressFormShape>();
  const [recipientAddress, setReceipientAddress] = useState<string>(''); // ETH address
  const [error, setError] = useState<string>('Loading token from wallet...');
  const [step, setStep] = useState<PuristStep>(PuristStep.Address);

  useEffect(() => {
    if(!open) {
      return;
    }
    getHasToken()
      .then(({hasToken, address}) => {
        setReceipientAddress(address);
        setHasToken(hasToken);
        if (!hasToken) {
          setError('There doesn\'t appear to be a token in the wallet you selected');
        } else {
          setError('');
        }
      }).catch(err => {
        console.error(err);
        setError('There was an error reading your wallet');
      })
  }, [open]);

  const handleAddressSubmit = useCallback(async (addressForm: AddressFormShape) => {
    if (!hasToken) {
      return;
    }
    setAddress(addressForm);
    setError('');

    const timestampDate = new Date();
    try {
      const signature = await signMessage({
        ETH_ADDRESS: recipientAddress,
        NAME: addressForm.fullName,
        TOKEN_ID: 'pre-minted token',
        TIMESTAMP: timestampDate.toISOString(),
      });

      await createOrder({
        address: addressForm,
        tokenId: 'pre-minted token',
        recipientAddress,
        signature,
        timestamp: timestampDate,
        isUpdate: true,
      });
    } catch(err) {
      setError('An unexpected error happened while signing your token.');
      return;
    }

    setStep(PuristStep.Confirmation);
  }, [setStep, setError, hasToken, recipientAddress, setAddress]);

  let modalContent;

  switch (step) {
    case PuristStep.Address:
      modalContent = <AddressForm submitError={error} onSubmit={handleAddressSubmit} />;
      break;
    case PuristStep.Confirmation:
      modalContent = <ConfirmationScreen address={address} onClose={onClose} />;
  }
  

  return (
    <Modal open={open} onClose={onClose} className={redeemStyles.redeemModal}>
      {modalContent}
    </Modal>
  );
};

export default PuristModal;