import cx from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import GalleryFilters, { GalleryFiltersOptions, GalleryFilterValues } from './components/gallery/GalleryFilters';
import GalleryHeader from "./components/gallery/GalleryHeader";
import TokenTile from './components/gallery/TokenTile';
import { getContract } from './crypto/config';

import { getCachedTokens, TokenCacheEntry, TokenObject } from './firebase/tokenCache';

import styles from './Gallery.module.scss';

interface GalleryProps {
  children?: React.ReactNode;
}


const filterOptions: GalleryFiltersOptions = [
  {
    label: 'STATUE',
    values: [
      'Jupiter',
      'Juno',
      'Neptune',
      'Ceres',
      'Minerva',
      'Apollo',
      'Diana',
      'Mars',
      'Venus',
      'Vulcan',
      'Mercury',
      'Vesta',
      'Bacchus',
      'Pluto',
    ],
  },
  {
    label: 'MATERIAL',
    values: [
      'Marble',
      'Bronze',
      'Porcelain',
      'Aquamarine',
      'Gold',
      'Silver',
      'Carbon Fiber',
      'Rainbow Voronoi',
      'Crystal',
      'Graphite',
      'Red Wax',
    ],
  },
  {
    label: 'HALO COLOR',
    values: [
      'Glowing gold',
      'Glowing two tone',
      'Kong green',
      'Vapor Pink',
    ]
  },
  {
    label: 'LIGHT',
    values: [
      'Neon',
      'Gold',
      'Blacklight',
    ],
  },
  {
    label: 'FACE',
    values: [
      'Black Heart',
      'Tear',
      'Dollar',
      'Yuan',
      'Euro',
      'Pound',
    ]
  },
  {
    label: 'EARRING',
    values: [
      'Sword Earring',
      'Diamond',
      'Cool S',
      'Geometric',
      'Gold Hoop',
    ],
  }
]

const filterTokens = (filters: GalleryFilterValues) => (token: TokenObject) => {
  let matchesFilters = true;
  Object.entries(filters).forEach(([filter, values]) => {
    matchesFilters = matchesFilters && Boolean(
        values.length === 0 || token.attributes.find(attr => attr.trait_type.toLowerCase() === filter.toLowerCase() && values.includes(attr.value))
      );
  });

  return matchesFilters;
};

const Gallery: React.FC<GalleryProps> = () => {
  const [selectedFilters, setSelectedFilters] = useState<GalleryFilterValues>({});
  const [ready, setReady] = useState<boolean>(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);
  // const [currentLimit, setCurrentLimit] = useState<number>(50);

  const [tokens, setTokens] = useState<TokenCacheEntry[]>([]);

  useEffect(() => {
    getCachedTokens().then((fetched) => {
      setTokens(fetched);
      setTimeout(setReady, 250, true);
    });
  }, []);

  const toggleFilter = useCallback((label: string, value: string) => {
    if (!selectedFilters[label]) {
      setSelectedFilters({
        ...selectedFilters,
        [label]: [value],
      });
    } else if (selectedFilters[label].includes(value)) {
      const ind = selectedFilters[label].indexOf(value);
      setSelectedFilters({
        ...selectedFilters,
        [label]: [...selectedFilters[label].slice(0, ind), ...selectedFilters[label].slice(ind + 1)],
      });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        [label]: selectedFilters[label].concat([value]),
      });
    }
  }, [selectedFilters, setSelectedFilters]);

  const resetFilters = useCallback(() => {
    setSelectedFilters({});
  }, [setSelectedFilters]);

  const closeMobileFilters = useCallback(() => {
    setMobileFiltersOpen(false);
  }, [setMobileFiltersOpen]);

  const tokenTiles = tokens
  .filter(filterTokens(selectedFilters))
  .map(token => (
    <TokenTile token={token} key={token.id} />
  ));
  const placeholderTiles = new Array(12 - tokens.length % 12).fill((<div className={styles.placeholder} />));

  const tokensContent = tokenTiles.length > 0 ? <>{tokenTiles}{placeholderTiles}</> : <div className={styles.emptyPlaceholder} />;

  return (<>
      {!ready && (
        <div className="loading-splash">
          <div>
            <div className="spinner"></div>
          </div>
        </div>
      )}
      <div className={cx('wrapper', ready && 'ready', styles.galleryWrapper)}>
      <GalleryHeader />
      <div className={styles.pageHeader}>
        <h1>The Agora</h1>
        <p>
        Human coordination is increasingly taking place not in physical locations, but rather digital forums. For the past decade these forums have been hosted, moderated and controlled by interests that are prone to arbitrary censorship.
        </p>
      </div>

      <div className={styles.galleryContent}>
        <div className={styles.filtersWrapper}>
          <GalleryFilters
            options={filterOptions}
            filters={selectedFilters}
            onSelect={toggleFilter}
            onReset={resetFilters}
            mobileShown={mobileFiltersOpen}
            onClose={closeMobileFilters}
          />
        </div>
        <div className={styles.tokens}>
          <div className={styles.tokensInner}>
            {
              tokensContent
            }          
          </div>
        </div>
      </div>
    </div>
  </>);
};  

export default Gallery;