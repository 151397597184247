import React, { useCallback, useState } from "react";
import { motion } from 'framer-motion';

import cx from 'classnames';
import styles from './GalleryFilters.module.scss';

import { ReactComponent as Hide } from './hide.svg';
import { ReactComponent as Show } from './show.svg';
import Button from "../Button";

interface GalleryFiltersProps {
  filters: GalleryFilterValues;
  options: GalleryFiltersOptions;
  onSelect: (label: string, value: string) => void;
  onReset: () => void;
  mobileShown: boolean;
  onClose: () => void;
}

export type GalleryFiltersOptions = ({ label: string, values: string[] })[];

export interface GalleryFilterValues {
  [label: string]: string[];
}
const GalleryFilters: React.FC<GalleryFiltersProps> = ({ filters, options, onSelect, onReset }) => {
  const [filterState, setFilterState] = useState<Record<string, boolean>>({});
  const [mobileShown, setMobileShown] = useState<boolean>(false);

  const toggleShown = useCallback(() => {
    setMobileShown(!mobileShown);
  }, [setMobileShown, mobileShown]);

  const toggleLabel = useCallback((label: string) => {
    setFilterState({
      ...filterState,
      [label]: !filterState[label],
    });
  }, [setFilterState, filterState])


  return (<>
  <div className={styles.mobileToggle} onClick={toggleShown}>
    <Show /> Filter
  </div>
  <div className={cx(styles.container, !mobileShown && styles.hide)}>
    <div className={styles.containerInner}>
      <p className={styles.title}>Filter <Show className={styles.closeX} onClick={toggleShown} /></p>
      {
        options.map((({ label, values }) => (
          <div key={label} className={styles.filter}>
            <p className={styles.filterToggle} onClick={() => toggleLabel(label)}>{filterState[label] ? <Hide /> : <Show />}{label}</p>
            <motion.div className={cx(styles.filterValues)} animate={{ height: filterState[label] ? 'auto' : 0, opacity: filterState[label] ? 1 : 0 }}>
              {
                values.map((value) => (/* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                  <a key={value} className={cx(filters[label]?.includes(value) && styles.selected)} onClick={() => onSelect(label, value)}>
                    {value}<Show className={styles.closeX} />
                  </a>
                ))
              }
            </motion.div>
          </div>
        )))
      }
      <Button small className={styles.resetFilters} onClick={onReset}>Reset Filters</Button>
      </div>
    </div>
    </>);
};

export default GalleryFilters;