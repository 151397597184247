import React from "react";
import cx from 'classnames';

import styles from './ModalHeader.module.scss';

interface ModalHeaderProps {
  title: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  tight?: boolean;
}
const ModalHeader: React.FC<ModalHeaderProps> = ({ icon, title, children, tight }) => {
  return (<>
    {icon}
    <h2 className={cx(styles.title, tight && styles.tight)}>{title}</h2>
    <p className={cx(styles.description, tight && styles.tight)}>{children}</p>
  </>);
};

export default ModalHeader;