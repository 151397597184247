import React, { useCallback, useEffect, useMemo, useState } from "react";
import cx from 'classnames';
import styles from './BadgeGrid.module.scss';

import { ReactComponent as PrevButton } from './lt.svg';
import { ReactComponent as NextButton } from './gt.svg';

interface BadgeGridProps {
  className?: string;
  images: ({ url: string; title: string; })[];
}
const BadgeGrid: React.FC<BadgeGridProps> = ({ className, images }) => {
  const [page, setPage] = useState<number>(0);
  const totalPages = Math.max(Math.ceil(images.length / 6), 2);

  useEffect(() => {
    if (page >= totalPages) {
      setPage(0);
    }
  }, [images]);

  const nextPage = useCallback(() => {
    if (page === totalPages - 1 ) {
      return;
    }
    setPage(page + 1);
  }, [page, setPage, totalPages]);
  const prevPage = useCallback(() => {
    if (page === 0) {
      return;
    }
    setPage(page - 1);
  }, [page, setPage]);

  const currentPage = useMemo(() => {
    const pageSlice = images.slice(page * 6, (page + 1) * 6);

    if (pageSlice.length === 6) {
      return pageSlice;
    }

    return pageSlice.concat(new Array(6 - pageSlice.length).fill({ url: '', title: '' }));
  }, [images, page]);

  console.log(currentPage);

  return (<div className={cx(styles.badgeGrid, className)}>
    {
      currentPage.map((item, ind) => (item.url ?
        <div className={styles.item}><img src={item.url} title={item.title} alt={item.title} key={`${item.url}_${ind}`} /></div> :
        <div className={styles.placeholder} key={`placeholder_${ind}`} />))
    }
    <div key="controls" className={styles.controls}>
      <PrevButton className={cx(styles.navButton, page === 0 && styles.disabled)} onClick={prevPage} />
      {
        new Array(totalPages).fill(null).map((_, ind) => (<div className={cx(styles.dotButton, page === ind && styles.disabled)} onClick={() => setPage(ind)} />))
      }
      <NextButton className={cx(styles.navButton, page === totalPages - 1 && styles.disabled)} onClick={nextPage} />
    </div>
    </div>);
};

export default BadgeGrid;