import { httpsCallable } from "@firebase/functions";
import React, { useCallback, useState } from "react";
import AppState from "../../AppState";
import claimName from "../../crypto/claimName";
import { functions } from "../../firebase/firebase";
import Button from "../Button";
import Modal from "../Modal";
import ModalHeader from "../ModalHeader";

import styles from './ClaimModal.module.scss';

interface ClaimModalProps {
  open: boolean;
  onClose: () => void;
  claimedName?: string;
}
const ClaimModal: React.FC<ClaimModalProps> = ({ open, onClose, claimedName }) => {
  const [name, setName] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  
  const handleNameChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setName(ev.target.value);
  }, [setName]);

  const handleSubmit = useCallback(async () => {
    if (!name || status === 'loading...') {
      return;
    }
    setStatus('loading...');

    try {
      await claimName(AppState.ownedTokenId, name, Boolean(claimedName));

      const resp = await httpsCallable<{owner: string, tokenId: string}, { error: { message: string } | null  }>(functions, 'claimName')({ owner: AppState.address, tokenId: AppState.ownedTokenId });

      if (resp.data?.error && resp.data.error.message) {
        setStatus(resp.data.error.message);
        return;
      }
    } catch (err: any) {
      if (typeof err?.message === 'string' && err.message.includes('Caller must own')) {
        setStatus('Failed to claim token, please ensure it is owned by the connected wallet');
      } else {
        console.error(err);
        setStatus('Unexpected error claiming token');
      }

      return;
    }

    setStatus('');
    onClose();
  }, [name, setStatus, status, onClose, claimedName]);


  return (<Modal open={open} onClose={onClose} closeable className={styles.modal}>
    <ModalHeader title={`${claimedName ? 'Set' : 'Add'} ETH Address`}>{status}</ModalHeader>

    <div className={styles.nameInput}>
      <input type="text" placeholder="kong" value={name} onChange={handleNameChange} />
      <div>citizen.eth</div>
    </div>

    <Button small className={styles.registerButton} onClick={handleSubmit} disabled={status === 'loading...'} >Register</Button>

  </Modal>);
};

export default ClaimModal;